import React from 'react'
import { usePapers } from '../hooks/usePapers'
import Meta from '../components/Meta'
import { PortableText } from '../components/PortableText'
import HubspotContactForm from '../components/HubspotContactForm'
import { Document, Page, pdfjs } from 'react-pdf'
import { PageNotFound } from '../components/PageNotFound'
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

function parseScriptInfo(script) {
  return script
    .trim()
    .split('{')[1]
    .split('}')[0]
    .split(',')
    .reduce((acc, cur) => {
      const [key, val] = cur.split(':')
      acc[key.trim()] = val.trim().slice(1, -1)
      return acc
    }, {})
}

const Papers = () => {
  const { papers } = usePapers()

  if (!papers) return <PageNotFound />

  const {
    title,
    _rawContent,
    _rawHubspotScript,
    filePreview,
    seo,
  } = papers
  const hubspotScript = _rawHubspotScript[0].children[0].text
  const scriptInfo = parseScriptInfo(hubspotScript)

  return (
    <section id="hero">
      <Meta pageTitle="Papers" props={seo} />
      <div className="w-full pt-24 pb-12 md:header-padding relative md:pb-[5%]">
        <div className="px-4 pt-9 pb-6 md:flex md:px-[5%] md:pt-[2%]">
          <div className="w-full md:w-3/4 stagger-container">
            <h1 className="h3 pb-[4%]">{title}</h1>
            <PortableText className="body-sm pb-[1%]" blocks={_rawContent} />
          </div>
        </div>
        <div className="px-4 pt-9 pb-6 md:flex flex-column md:flex-row md:px-[5%] md:pt-[2%] align-start">
          <HubspotContactForm scriptInfo={scriptInfo} />
          <div>
            <p className="h5 pb-[3%] text-center">{filePreview.displayName}</p>
            <Document file={filePreview.document.asset.url} className="papers-pdf-document">
              <Page pageNumber={1} className="papers-pdf-page" />
            </Document>
          </div>
        </div>

      </div>
    </section>
  )
}

export default Papers
