import React, { useEffect } from 'react'

const HubspotContactForm = props => {
  const { scriptInfo } = props
  const { region, portalId, formId } = scriptInfo

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/shell.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: region,
          portalId: portalId,
          formId: formId,
          target: '#hubspotForm',
        })
      }
    })
  }, [region, portalId, formId])

  return (
    <div id="hubspotForm" className="w-5/6 md:w-1/2 max-w-xl mx-auto pb-10 bg-white"></div>
  )
}

export default HubspotContactForm
